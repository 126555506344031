import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Paper, Checkbox, TablePagination, TableFooter, InputBase, IconButton, debounce } from "@mui/material";
import styles from './Insurance.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import SearchIcon from '@mui/icons-material/Search';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import EditInsuranceModal from './EditInsuranceModal';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { setSearchValue, handleGetCustomers, selectState, setPage, setRowsPerPage, setSort, setSortBy, setSelectedCustomer, setSelectedId } from './insuranceSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import { setSuccessMessage, setErrorMessage } from './insuranceSlice';
import useToasts from '../../../hooks/useToasts';

export function Insurance() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetCustomers());
  }, [state.sort, state.sortBy, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetCustomers());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetCustomers());
  };

  const handleRequestSort = (property) => {
    const isDescending = state.sortBy === property && state.sort === 'desc';
    dispatch(setSort(isDescending ? 'asc' : 'desc'));
    dispatch(setSortBy(property));
  };

  const onSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    dispatch(setPage(0));
    dispatch(setSearchValue(cleanValue));
    dispatch(handleGetCustomers());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                numSelected={state.selectedId === null ? 0 : 1}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Full Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>IC Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Passport Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Occupation</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vehicle #1</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vehicle #2</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.sortBy === 'insurance.startDate'}
                    direction={state.sortBy === 'insurance.startDate' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('insurance.startDate')}
                  >
                    Insurance Start Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Insurance Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Insurance Schedule ID</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.customers.length > 0 ? (
                  state.customers.map((customer) => (
                    <TableRow
                      key={customer.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === customer.id ? null : customer.id)); dispatch(setSelectedCustomer(state.selectedId === customer.id ? null : customer)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === customer.id ? true : false}
                      selected={state.selectedId === customer.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === customer.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${customer.user?.firstName} ${customer.user?.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.user?.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.identificationCard}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.passportNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.occupation}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.vehicles.length > 0 ? `${customer.vehicles[0].registrationNumber} (${customer.vehicles[0].color} ${customer.vehicles[0].model})` : ""}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.vehicles.length > 1 ? `${customer.vehicles[1].registrationNumber} (${customer.vehicles[1].color} ${customer.vehicles[1].model})` : ""}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.insurance?.startDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.insurance?.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{customer.insurance?.subscriptionId}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No insurance customers.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.editModalOpen && <EditInsuranceModal />}
    </div>
  );
}