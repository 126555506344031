import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getAllCustomers = async (page, size, sort, sortBy, search) =>
    axiosApiInstance.get(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}${search ? '&search=' + search : ''}`,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );

export const getCustomerWithInsurance = async (page, size, sort, sortBy, insuranceStatus, search) =>
    axiosApiInstance.get(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}&insuranceStatus=${insuranceStatus}${search ? '&search=' + search : ''}`,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );

export const getCustomerByUsername = async (username) =>
    axiosApiInstance.get(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers?userName=${username}`,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );

export const updateCustomer = async (data) =>
    axiosApiInstance.put(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers/update`, data,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );