import { Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { ActionablesTitle } from "../../../app/constants";
import { setListingActionUrl, setListingActionMethod, showApproveListingTerminationModal, showRejectListingTerminationModal, showCancelListingTerminateModal } from "./terminationSlice";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const ListingTableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected, title, actionables = [] } = props;

    const approveAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Approve)[0];
    const rejectAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Reject)[0];
    const cancelTerminateAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.CancelTerminate)[0];

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <div className="flex w-full flex-row justify-between items-center">
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <div className="flex flex-row items-center gap-2">
                        {approveAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" 
                                onClick={() => { 
                                    dispatch(showApproveListingTerminationModal(true));
                                    dispatch(setListingActionUrl(approveAction.url));
                                    dispatch(setListingActionMethod(approveAction.type));
                                }}
                            >
                                Approve
                            </button>
                        )}
                        {rejectAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" 
                                onClick={() => { 
                                    dispatch(showRejectListingTerminationModal(true));
                                    dispatch(setListingActionUrl(rejectAction.url));
                                    dispatch(setListingActionMethod(rejectAction.type));
                                }}
                            >
                                Reject
                            </button>
                        )}
                        {cancelTerminateAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" 
                                onClick={() => { 
                                    dispatch(showCancelListingTerminateModal(true));
                                    dispatch(setListingActionUrl(cancelTerminateAction.url));
                                    dispatch(setListingActionMethod(cancelTerminateAction.type));
                                }}
                            >
                                Cancel Terminate
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
        </Toolbar>
    );
};