import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, InputAdornment, ListSubheader } from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SearchIcon from "@mui/icons-material/Search";
import { closeCreateVoucherModal, handleGetVouchers } from './voucherSlice';
import * as Api from "./voucherAPI";
import * as UserApi from "./../../api/usersAPI";
import { Close, ChevronLeft } from "@mui/icons-material";
import styles from './Voucher.module.css';
import { dollarToCent } from '../../app/utils';
import moment from 'moment';

export function CreateVoucher(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      code: "",
      title: "",
      detail: "",
      expiryDate: null,
      discountAmount: null,
      discountPercentage: null,
      redeemLimit: null,
      perUserRedeemLimit: null,
      whiteList: []
    },
    users: [],
    showSummary: false,
    error: false,
    errorMessage: ""
  };

  const [voucherState, _setVoucherState] = useStateWithCallbackLazy({
    ...initialState
  });
  const voucherStateRef = useRef(voucherState);
  const [init, setInit] = useState(false);

  const setVoucherState = useCallback(data => {
    voucherStateRef.current = data;
    _setVoucherState(data);
  }, [_setVoucherState]);

  const onFormValueChange = (data) => {
    setVoucherState({
      ...voucherState,
      form: {
        ...voucherState.form,
        [data.name]: data.value
      }
    })
  }

  const handleGetUsers = useCallback(async (userSearchValue) => {
    try {
      const res = await UserApi.getUsers(0, 100, userSearchValue);
      if (res.error) {
        throw res.error;
      };
      const { content } = res;

      setVoucherState({
        ...voucherState,
        users: content,
      })
    } catch (err) {
      console.log(err);
    }
  }, [voucherState, setVoucherState]);

  const handleWhiteListChange = (event) => {
    const selectedIds = event.target.value;
    setVoucherState({
      ...voucherState,
      form: {
        ...voucherState.form,
        whiteList: selectedIds
      }
    });
  };

  useEffect(() => {
    if (!init) {
      handleGetUsers();
      setInit(true);
    }
  }, [init, handleGetUsers])

  const showSummary = () => {
    if (voucherState.form.code === "") {
      setVoucherState({...voucherState, error: true, errorMessage: "Voucher code is required"});
      return;
    }

    if (voucherState.form.code.length < 6  || voucherState.form.code.length > 10) {
      setVoucherState({...voucherState, error: true, errorMessage: "Voucher code must be 6 to 10 characters long"});
      return;
    }

    if (!voucherState.form.discountAmount && !voucherState.form.discountPercentage) {
      setVoucherState({...voucherState, error: true, errorMessage: "A voucher must include either a discount amount or a discount percentage."});
      return;
    }

    if (voucherState.form.discountPercentage !== null && voucherState.form.discountPercentage > 100) {
      setVoucherState({...voucherState, error: true, errorMessage: "Discount percentage cannot more than 100"});
      return;
    }

    if (voucherState.form.discountAmount !== null && voucherState.form.discountPercentage !== null) {
      setVoucherState({...voucherState, error: true, errorMessage: "A voucher may only contain either a discount amount or a discount percentage, but not both."});
      return;
    }

    setVoucherState({
      ...voucherState,
      showSummary: true,
      error: false,
      form: {
        ...voucherState.form
      }
    })
  }

  const hideSummary = () => {
    setVoucherState({
      ...voucherState,
      showSummary: false,
      form: {
        ...voucherState.form
      }
    })
  }

  const submit = async () => {
    if (!voucherState.error) {
      try {
        const payload = {
          ...voucherState.form,
          discountAmount: voucherState.form.discountAmount ? dollarToCent(voucherState.form.discountAmount) : null
        }
        const res = await Api.createVoucher(payload);
        if (res.error) {
          let errortxt = await res.json()
          throw errortxt.message
        }
        dispatch(handleGetVouchers());
        dispatch(closeCreateVoucherModal());
      } catch (err) {
        setVoucherState({...voucherState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-user-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateVoucherModal())}/>
          {
            !!voucherState.showSummary &&
            <span className={styles.backButton} onClick={hideSummary}><ChevronLeft fontSize="small" style={{float: "left"}} /> Back</span>
          }
          {
            !voucherState.showSummary &&
            <>
              <h3 className={styles.subtitle}>Create Voucher</h3>
              <Fade in={voucherState.error}>
                <p className={styles.errorMessage} style={{marginBottom: 25}}>{voucherState.errorMessage}</p>
              </Fade>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Voucher Code" variant="outlined" value={voucherState.form.code} onChange={e => onFormValueChange({name: "code", value: e.target.value.toUpperCase()})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Voucher Title" variant="outlined" value={voucherState.form.title} onChange={e => onFormValueChange({name: "title", value: e.target.value})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Voucher Detail" variant="outlined" value={voucherState.form.detail} onChange={e => onFormValueChange({name: "detail", value: e.target.value})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Discount Amount (RM)" type="number" InputProps={{ onWheel: (e) => e.target.blur() }} value={voucherState.form.discountAmount} variant="outlined" onChange={e => onFormValueChange({name: "discountAmount", value: e.target.value ? parseInt(e.target.value) : null})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Discount Percentage (%)" type="number" InputProps={{ onWheel: (e) => e.target.blur() }} value={voucherState.form.discountPercentage} variant="outlined" onChange={e => onFormValueChange({name: "discountPercentage", value: e.target.value ? parseInt(e.target.value) : null})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Redeem Limit" type="number" InputProps={{ onWheel: (e) => e.target.blur() }} variant="outlined" value={voucherState.form.redeemLimit} onChange={e => onFormValueChange({name: "redeemLimit", value: e.target.value})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="outlined-basic" label="Redeem Limit Per User" type="number" InputProps={{ onWheel: (e) => e.target.blur() }} variant="outlined" value={voucherState.form.perUserRedeemLimit} onChange={e => onFormValueChange({name: "perUserRedeemLimit", value: e.target.value})} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Expiry Date"
                    inputFormat="MM/dd/yyyy"
                    value={voucherState.form.expiryDate}
                    onChange={date => onFormValueChange({name: "expiryDate", value: moment(date).format("YYYY-MM-DD")})}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-name-label">White List User</InputLabel>                    
                    <Select
                      multiple
                      variant='outlined'
                      value={voucherState.form.whiteList}
                      onChange={handleWhiteListChange}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          // Autofocus on textfield
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => handleGetUsers(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {voucherState.users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {`${user.userName} - ${user.firstName} ${user.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Only selected user can use this voucher</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.submitButtonContainer}>
                    <Button onClick={showSummary} className={styles.gradientButton}>Next</Button>
                  </div>
                </Grid>
              </Grid>
            </>
          }

          {
            voucherState.showSummary &&
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p>Voucher Code: {voucherState.form.code}</p>
              </Grid>
              <Grid item xs={6}>
                <p>Voucher Title: {voucherState.form.title}</p>
              </Grid>
              <Grid item xs={6}>
                <p>Voucher Detail: {voucherState.form.detail}</p>
              </Grid>
              <Grid item xs={6}>
                <p>Discount Value: {voucherState.form.discountAmount ? `RM${voucherState.form.discountAmount}` : `${voucherState.form.discountPercentage}%` }</p>
              </Grid>
              <Grid item xs={6}>
                <p>Redeem Limit: {voucherState.form.redeemLimit ?? "No limit"}</p>
              </Grid>
              <Grid item xs={6}>
                <p>Redeem Limit Per User: {voucherState.form.perUserRedeemLimit ?? "No limit per user"}</p>
              </Grid>
              <Grid item xs={6}>
                <p>Expiry Date: {voucherState.form.expiryDate ? voucherState.form.expiryDate :  "No expiry"}</p>
              </Grid>
              <Grid item xs={6}>
                <p>WhiteList: {voucherState.form.whiteList}</p>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.submitButtonContainer}>
                  <Button onClick={submit} className={styles.gradientButton}>Create</Button>
                </div>
              </Grid>
            </Grid>
          }
        </div>
      </Paper>
    </Modal>
  );
}
