import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, FormLabel, Typography, Checkbox, Button, FormControlLabel, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem } from "@mui/material";
import { Close } from "@mui/icons-material";
import { selectState, closeRecreateSubscriptionModal, handleRecreateSubcription } from './subscriptionSlice';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import useDisableWhileRequesting from "./../../hooks/useDisableWhileRequesting";
import { centToDollar, dollarToCent } from '../../app/utils';

export function RecreateSubscriptionModal() {
    const state = useSelector(selectState);
    const dispatch = useDispatch();
    const subscription = state.selectedSubscription;

    const [interval, setInterval] = useState(subscription.interval);
    const [unitAmount, setUnitAmount] = useState(centToDollar(subscription.unitAmount));
    const [startDate, setStartDate] = useState(subscription.nextBillingDate);
    
    const { isDisabled, withDisable } = useDisableWhileRequesting();
    const [isVerified, setIsVerified] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsVerified(event.target.checked);
    };

    const submit = async () => {
        try{
            const payload = {
                subscriptionId: subscription.id,
                walletId: subscription.walletId,
                newInterval: interval,
                newUnitAmount: dollarToCent(unitAmount),
                newStartDate: startDate
            }
            await withDisable(() => dispatch(handleRecreateSubcription(payload)));
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <Modal
        open={true}
        aria-labelledby="recreate-subscription-modal"
        className="flex p-[10px] items-center justify-center"
        >
        <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
            <Close
            className="float-right cursor-pointer"
            onClick={() => dispatch(closeRecreateSubscriptionModal())}
            />
            <div className="px-[20px] pt-[10px] pb-[15px]">
                <p className="mb-[10px] text-[20px] font-medium">Recreate Subscription</p>
                <FormLabel style={{ marginBottom: '20px' }} component="legend">This subscription will be terminated, paused, and recreated as a new subscription.</FormLabel>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <strong>Subscription Id: </strong> {subscription.id}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <strong>Wallet Id: </strong> {subscription.walletId}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <strong>Charge Status: </strong> {subscription.chargeStatus}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>
                            <strong>Next Billing Date </strong> {subscription.nextBillingDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl margin="normal" variant="outlined" fullWidth>
                            <InputLabel htmlFor="interval">Subscription Interval</InputLabel>
                            <Select
                                fullWidth
                                onChange={(e) => setInterval(e.target.value)}
                                value={interval}
                                input={<OutlinedInput name="subscriptionInterval" id="subscriptionInterval" label="Subscription Interval" />}
                            >
                                <MenuItem key="WEEKLY" value="WEEKLY">Weekly</MenuItem>
                                <MenuItem key="BI_WEEKLY" value="BI_WEEKLY">Bi-weekly</MenuItem>
                                <MenuItem key="MONTHLY" value="MONTHLY">Monthly</MenuItem>
                                <MenuItem key="QUARTERLY" value="QUARTERLY">Quarterly</MenuItem>
                                <MenuItem key="HALF_YEARLY" value="HALF_YEARLY">Half-yearly</MenuItem>
                                <MenuItem key="ANNUALLY" value="ANNUALLY">Annually</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="unitAmount"
                            label="Unit Amount"
                            onChange={(e) => setUnitAmount(e.target.value)}
                            value={unitAmount}
                            margin="normal"
                            variant="outlined"
                            type="number"
                            inputProps={{min: '0', onWheel: (e) => e.target.blur()}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl margin="normal" variant="outlined" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={setStartDate}
                                    componentsProps= {{
                                        actionBar: {
                                            actions: ['clear', 'accept'],
                                        },
                                    }}
                                    renderInput={(params) => <TextField {...params} required={!startDate} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isVerified}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label={
                                <span>
                                    I hereby acknowledge to recreate this subscription. 
                                    <span style={{ color: 'red' }}>
                                        *This action cannot be reverted.*
                                    </span>
                                </span>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} className="flex justify-center">
                        <Button 
                            variant="contained" 
                            onClick={submit}
                            disabled={!isVerified || isDisabled}
                            sx={{
                            backgroundColor: '#DE4D65',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#b93a4e', // Slightly darker shade on hover
                            },
                            marginTop: '20px'
                            }}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    </Modal>
    );
}