import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { SubscriptionChargeStatus } from '../../../app/constants';
import { getParkitSubscriptions, pauseChargeSubscription, recreateSubscription } from '../../../api/parkitSubscriptionAPI';

export const subscriptionSlice = createSlice({
  name: 'parkitSubscription',
  initialState: {
    subscriptions: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    selectedWalletId: null,
    selectedSubscription: null,
    successMessage: "",
    errorMessage: "",
    confirmPauseChargeModalOpen: false,
    recreateSubscriptionModalOpen: false
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedWalletId: (state, action) => {
      state.selectedWalletId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    },
    setSelectedSubscription: (state, action) => {
      state.selectedSubscription = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    openConfirmPauseChargeModal: (state) => {
      state.confirmPauseChargeModalOpen = true;
    },
    closeConfirmPauseChargeModal: (state) => {
      state.confirmPauseChargeModalOpen = false;
    },
    openRecreateSubscriptionModal: (state) => {
      state.recreateSubscriptionModalOpen = true;
    },
    closeRecreateSubscriptionModal: (state) => {
      state.recreateSubscriptionModalOpen = false;
    },
  }
});

export const { openRecreateSubscriptionModal, closeRecreateSubscriptionModal, setSelectedSubscription, setSelectedWalletId, openConfirmPauseChargeModal, closeConfirmPauseChargeModal, setSelectedId, setPage, setRowsPerPage, setPagination, setSubscriptions, setSuccessMessage, setErrorMessage } = subscriptionSlice.actions;

export const selectState = state => state.parkitSubscription;

export const handleGeSubscriptions = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getParkitSubscriptions(state.parkitSubscription.page, state.parkitSubscription.rowsPerPage, `${SubscriptionChargeStatus.PENDING},${SubscriptionChargeStatus.PAID},${SubscriptionChargeStatus.PAUSE_CHARGE}`);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setSubscriptions(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setSubscriptions([]));
  } finally {
    dispatch(hideLoading());
  }
}

export const handlePauseCharge = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    await pauseChargeSubscription(state.parkitSubscription.selectedId, state.parkitSubscription.selectedWalletId);
    dispatch(handleGeSubscriptions());
    dispatch(closeConfirmPauseChargeModal());
    dispatch(setSuccessMessage('Subscription pause charge requested successfully'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleRecreateSubcription = (payload) => async (dispatch) => {
  dispatch(showLoading());
  try {
    await recreateSubscription(payload);
    dispatch(handleGeSubscriptions());
    dispatch(closeRecreateSubscriptionModal());
    dispatch(setSuccessMessage('Recreate subscription requested successfully'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default subscriptionSlice.reducer;