import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Paper, Grid, TextField, CircularProgress, FormControl, InputLabel, Select, OutlinedInput, MenuItem } from "@mui/material";
import { Close } from "@mui/icons-material";
import SearchableSelect from 'react-select';
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import useToasts from '../../../hooks/useToasts';
import { getAllParkingAreas } from '../parkingArea/parkingAreaAPI';
import { handleApproveListing, showApproveListingModal, setErrorMessage } from './ownerListingsSlice';
import { BusinessType } from '../../../app/constants';

const ApproveListingModal = () => {
  const dispatch = useDispatch();
  const { toastError } = useToasts();
  const [parkingAreaLoading, setParkingAreaLoading] = useState(false);
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const [parkingAreas, setParkingAreas] = useState([]);
  const [parkingAreaName, setParkingAreaName] = useState('');
  const [parkingAreaId, setParkingAreaId] = useState('');
  const [monthlyBuyPrice, setMonthlyBuyPrice] = useState(null);
  const [businessType, setBusinessType] = useState(BusinessType.BUSINESS_TO_CONSUMER);

  useEffect(() => {
    const handleGetParkingAreas = async () => {
      setParkingAreaLoading(true);
      try {
        const response = await getAllParkingAreas(0, 999999, "desc", "createdAt");
        const { content } = response.data;
        setParkingAreas(content);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch parking areas."));
      } finally {
        setParkingAreaLoading(false);
      }
    }
    handleGetParkingAreas();
  }, [dispatch]);

  const validateFields = () => {
    if (!parkingAreaId || !monthlyBuyPrice) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        const payload = {
          parkingAreaId: parkingAreaId,
          buyPrices: {
            monthly: monthlyBuyPrice * 100
          },
          businessType
        }
        await withDisable(() => dispatch(handleApproveListing(payload)));
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="approve-listing-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showApproveListingModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Approve Listing</p>
          <Grid container spacing={1}>
            <Grid item xs={12} zIndex={999}>
              <p className="my-[5px] text-[#929292] text-[14px]">Parking Area</p>
              {parkingAreaLoading ? (
                <CircularProgress />
              ) : (
                <SearchableSelect
                  className="z-10"
                  placeholder="Parking Areas"
                  value={{ value: parkingAreaId, label: parkingAreaName }}
                  onChange={(e) => {
                    setParkingAreaId(e.value);
                    setParkingAreaName(e.label);
                  }}
                  options={[...parkingAreas.map(area => { return { value: area.id, label: area.name } })]}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="monthlyBuyPrice"
                label="Monthly Buy Price"
                onChange={e => setMonthlyBuyPrice(e.target.value)}
                value={monthlyBuyPrice}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="business-type">Business Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setBusinessType(e.target.value)}
                  value={businessType}
                  input={<OutlinedInput name="businessType" id="business-type" label="Business Type" />}
                >
                  <MenuItem key={BusinessType.BUSINESS_TO_BUSINESS} value={BusinessType.BUSINESS_TO_BUSINESS}>B2B</MenuItem>
                  <MenuItem key={BusinessType.BUSINESS_TO_CONSUMER} value={BusinessType.BUSINESS_TO_CONSUMER}>B2C</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Approve
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default ApproveListingModal;