import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import TopUpIcon from '@mui/icons-material/MonetizationOn';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useDispatch } from "react-redux";
import { showEditCustomerModal } from "./customerSlice";

export const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const { selectedUserId, numSelected, adminTopUp } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <div className="flex w-full flex-row justify-between items-center">
                    <Typography color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <div className="flex flex-row items-center gap-2">
                        <button className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showEditCustomerModal(true))}>Edit Customer</button>
                        <Tooltip title="Manual top up" onClick={adminTopUp}>
                            <IconButton aria-label="topup">
                                <TopUpIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View subscriptions" onClick={() => window.open(`/users/${selectedUserId}/subscriptions`, '_blank')}>
                            <IconButton aria-label="subscriptions">
                                <WalletIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <>
                    <Typography variant="h6" id="tableTitle" component="div" style={{flex: '1 1 100%'}}>
                        Customers (formerly known as Parkit User List)
                    </Typography>
                </>
            )}
        </Toolbar>
    );
};