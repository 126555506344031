import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Paper, Grid, TextField, CircularProgress, Switch, Button, Typography, List, ListItem, ListItemText, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import SearchableSelect from "react-select";
import moment from "moment";
import useDisableWhileRequesting from "./../../../hooks/useDisableWhileRequesting";

import { handleAddRenting, showAddModal, setErrorMessage } from "./rentingSlice";
import { getAllParkingAreas } from "../parkingArea/parkingAreaAPI";
import { getCustomerByUsername } from "../../../api/customerAPI";
import { createUserVehicle, getUserVehicles } from "./../../../api/vehicleAPI";
import useToasts from "../../../hooks/useToasts";

const AddRentingModal = () => {
  const dispatch = useDispatch();
  const { toastError } = useToasts();
  const [parkingAreaLoading, setParkingAreaLoading] = useState(false);
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const [parkingAreas, setParkingAreas] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [areaName, setAreaName] = useState("");
  const [username, setUsername] = useState("");
  const [startDate, setStartDate] = useState();
  const [manualPayment, setManualPayment] = useState(false);
  const [paid, setPaid] = useState(false);
  const [depositPaid, setDepositPaid] = useState(false);
  const [adminFeePaid, setAdminFeePaid] = useState(false);

  const [showNewVehicleForm, setShowNewVehicleForm] = useState(false);
  const [showRegisteredVehicleList, setShowRegisteredVehicleList] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [model, setModel] = useState("");
  const [color, setColor] = useState("");

  const [registeredVehicles, setRegisteredVehicles] = useState([]);

  useEffect(() => {
    const handleGetParkingAreas = async () => {
      setParkingAreaLoading(true);
      try {
        const response = await getAllParkingAreas(0, 999999, "desc", "createdAt");
        const { content } = response.data;
        setParkingAreas(content);
      } catch (error) {
        dispatch(setErrorMessage("Failed to fetch parking areas."));
      } finally {
        setParkingAreaLoading(false);
      }
    };
    handleGetParkingAreas();
  }, [dispatch]);

  const validateFields = () => {
    if (!username || !areaId || !startDate || (showNewVehicleForm ? !registrationNumber || !model || !color : !vehicleId)) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        const customerResponse = await getCustomerByUsername(username);
        const customerId = customerResponse.data.id;
        let formattedStartDate = moment(startDate).format("YYYY-MM-DD");
        let rentingVehicleId = vehicleId;

        if (showNewVehicleForm) {
          const vehiclePayload = {
            registrationNumber,
            model,
            color
          };
          const vehicleResponse = await createUserVehicle(username, vehiclePayload);
          rentingVehicleId = vehicleResponse.data.id;
        }

        const payload = {
          customer: {
            id: customerId
          },
          requestedParkingAreaId: areaId,
          startDate: formattedStartDate,
          manualPayment,
          paid,
          depositPaid,
          adminFeePaid,
          vehicle: {
            id: rentingVehicleId
          }
        };

        await withDisable(() => dispatch(handleAddRenting(payload)));
      } catch (error) {
        dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
      }
    }
  };

  const fetchRegisteredVehicles = async () => {
    if (username) {
      try {
        const userVehiclesResponse = await getUserVehicles(username);
        setRegisteredVehicles(userVehiclesResponse.data);
        setShowRegisteredVehicleList(true);
      } catch (error) {
        dispatch(setErrorMessage(`Failed to fetch ${username} vehicles.`));
      }
    } else {
      dispatch(setErrorMessage("Please Provide Username."));
    }
  }

  const handleVehicleBack = () => {
    setShowNewVehicleForm(false);
    setShowRegisteredVehicleList(false);
    setVehicleId(null);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showAddModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Add Renting</p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="username"
                label="Username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} zIndex={999}>
              <p className="my-[5px] text-[#929292] text-[14px]">
                Parking Area
              </p>
              {parkingAreaLoading ? (
                <CircularProgress />
              ) : (
                <SearchableSelect
                  placeholder="Parking Area"
                  value={{ value: areaId, label: areaName }}
                  onChange={(e) => {
                    setAreaId(e.value);
                    setAreaName(e.label);
                  }}
                  options={[
                    ...parkingAreas.map((area) => {
                      return {
                        label: area.name,
                        value: area.id,
                      };
                    }),
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                margin="normal"
                variant="outlined"
                type="date"
                fullWidth
                required
              />
            </Grid>
            {!showNewVehicleForm && !showRegisteredVehicleList && (
              <>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={() => setShowNewVehicleForm(true)}>
                      New Vehicle
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => fetchRegisteredVehicles()} style={{ marginLeft: '16px' }}>
                      Registered Vehicle
                    </Button>
                  </Box>
                </Grid>
              </>
            )}

            {showNewVehicleForm && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="vehicleRegistrationNumber"
                    label="Vehicle Registration Number"
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    value={registrationNumber}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="vehicleModel"
                    label="Vehicle Model"
                    onChange={(e) => setModel(e.target.value)}
                    value={model}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="vehicleColor"
                    label="Vehicle Color"
                    onChange={(e) => setColor(e.target.value)}
                    value={color}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="outlined" onClick={handleVehicleBack}>
                      Return to Vehicle Selection
                    </Button>
                  </Box>
                </Grid>
              </>
            )}

            {showRegisteredVehicleList && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Select Registered Vehicle</Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {registeredVehicles.map((vehicle) => (
                      <ListItem
                        key={vehicle.id}
                        button
                        onClick={() => setVehicleId(vehicle.id)}
                        selected={vehicleId === vehicle.id}
                      >
                        <ListItemText
                          primary={`Registration: ${vehicle.registrationNumber}`}
                          secondary={`Model: ${vehicle.model}, Color: ${vehicle.color}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="outlined" onClick={handleVehicleBack}>
                      Return to Vehicle Selection
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Manual Payment</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={manualPayment}
                    onChange={(e) => setManualPayment(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Paid</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={paid}
                    onChange={(e) => setPaid(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Deposit Paid</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={depositPaid}
                    onChange={(e) => setDepositPaid(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Admin Fee Paid</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={adminFeePaid}
                    onChange={(e) => setAdminFeePaid(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Add
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default AddRentingModal;
