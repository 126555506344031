import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getAllCustomers, updateCustomer } from '../../../api/customerAPI';
import { updateUser } from "../../../api/usersAPI";
import { getUserWallet, updateWallet } from "../../../api/walletAPI";
import { updateVehicle } from '../../../api/vehicleAPI';

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customers: [],
    adminTopUpModalOpen: false,
    walletTransactionModalOpen: false,
    editCustomerModalOpen: false,
    searchValue: '',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'id',
    selectedId: null,
    selectedCustomer: null,
    selectedUserId: null,
    selectedWalletId: null,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    showAdminTopUpModal: (state, action) => {
      state.adminTopUpModalOpen = action.payload;
    },
    showWalletTransactionModal: (state, action) => {
      state.walletTransactionModalOpen = action.payload;
    },
    showEditCustomerModal: (state, action) => {
      state.editCustomerModalOpen = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    setSelectedWalletId: (state, action) => {
      state.selectedWalletId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setSelectedUserId, setSearchValue, showAdminTopUpModal, showWalletTransactionModal, showEditCustomerModal, setSelectedId, setSelectedWalletId, setPage, setRowsPerPage, setPagination, setCustomers, setSuccessMessage, setSelectedCustomer, setErrorMessage } = customerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.customer.value)`
export const selectState = state => state.customer;

const handleAddWalletToCustomers = async (customers) => {
  return await Promise.all(
    customers.map(async (customer) => {
      try {
        const wallet = await getUserWallet(customer.user.id);
        const user = { ...customer.user, wallet };
        return { ...customer, user};
      } catch {
        return { ...customer };
      }
    })
  );
}

export const handleGetCustomers = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllCustomers(state.customer.page, state.customer.rowsPerPage, state.customer.sort, state.customer.sortBy, state.customer.searchValue)
    const { content, ...pagination } = response.data;

    const customerWithWallet = await handleAddWalletToCustomers(content);
    dispatch(setPagination(pagination));
    dispatch(setCustomers(customerWithWallet));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateUser = (id, user) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateUser(id, user);
    dispatch(showEditCustomerModal(false));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update user: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateCustomer = (customer) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateCustomer(customer);
    dispatch(showEditCustomerModal(false));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update customer: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateWallet = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateWallet(id, payload);
  } catch (error) {
    dispatch(setErrorMessage("Failed to update wallet: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateVehicle = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateVehicle(id, payload);
  } catch (error) {
    dispatch(setErrorMessage("Failed to update vehicle: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default customerSlice.reducer;
