import React, { useEffect, useState } from "react";
import {
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  styled,
} from '@mui/material';
import { CreditScoreOutlined, CreditCardOffOutlined, Menu as MenuIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { openParkitNav, closeParkitNav, selectState as selectParkitNav } from './parkitNavSlice';

import { ReactComponent as CollapseMenuIcon } from '../../assets/icons/collapse_menu.svg';
import { ReactComponent as AppointmentStatusOnfleetIcon } from '../../assets/icons/appointment_status_onfleet.svg';
import { ReactComponent as OwnerListingsIcon } from '../../assets/icons/owner_listings.svg';
import { ReactComponent as AddParkingBuildingIcon } from '../../assets/icons/add_parking_building.svg';
import { ReactComponent as BuildingEnquiryIcon } from '../../assets/icons/building_enquiry.svg';
import { ReactComponent as ParkingInventoryIcon } from '../../assets/icons/parking_inventory.svg';
import { ReactComponent as MasterlistIcon } from '../../assets/icons/master_list.svg';
import { ReactComponent as DriverEnquiryIcon } from '../../assets/icons/driver_enquiry.svg';
import { ReactComponent as TrackingSheetIcon } from '../../assets/icons/tracking_sheet.svg';
import { ReactComponent as TerminationIcon } from '../../assets/icons/termination_request.svg';
import { ReactComponent as BulkEnquiriesIcon } from '../../assets/icons/bulk_enquiries.svg';
import { ReactComponent as ArchivedListingsIcon } from '../../assets/icons/archived_listings.svg';
import { ReactComponent as ArchivedRentingsIcon } from '../../assets/icons/archived_rentings.svg';
import { ReactComponent as InsuranceIcon } from '../../assets/icons/insurance.svg';
import { ReactComponent as DeliveryRateIcon } from '../../assets/icons/delivery_rate.svg';
import { ReactComponent as ParkitUserIcon } from '../../assets/icons/users.svg';
import { ReactComponent as DeleteUserIcon } from '../../assets/icons/delete-user.svg';
import { ReactComponent as AffiliateCampaignIcon } from '../../assets/icons/affiliate_campaign.svg';
import { ReactComponent as PayoutReportIcon } from '../../assets/icons/payout_report.svg';
import { ReactComponent as PayoutIcon } from '../../assets/icons/payout.svg';

const ParkitNav = () => {
  const { open } = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const [toolbarHeight, setToolbarHeight] = useState(69);

  useEffect(() => {
    const handleResize = () => {
      const toolbar = document.getElementById('appBar');
      if (toolbar) {
        setToolbarHeight(toolbar.offsetHeight);
      }
    };

    // Initial measurement
    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const pages = [{
    label: 'Driver Enquiries',
    path: '/parkit/rentings',
    icon: <DriverEnquiryIcon width={20} height={20} />,
  }, {
    label: 'CP Building Enquiries',
    path: '/parkit/building-enquiries',
    icon: <BuildingEnquiryIcon width={20} height={20} />,
  }, {
    label: 'Bulk Enquiries',
    path: '/parkit/bulk-enquiries',
    icon: <BulkEnquiriesIcon width={20} height={20} />,
  }, {
    label: 'Subscriptions',
    path: '/parkit/subscriptions',
    icon: <CreditScoreOutlined className="text-[#D9E2FF]" width={20} height={20} />,
  }, {
    label: 'Failed Subscriptions',
    path: '/parkit/failed-subscriptions',
    icon: <CreditCardOffOutlined className="text-[#D9E2FF]" width={20} height={20} />,
  }, {
    label: 'Tracking Sheet',
    path: '/parkit/tracking-rentings',
    icon: <TrackingSheetIcon width={20} height={20} />,
  }, {
    label: 'Parking Areas',
    path: '/parkit/parking-areas',
    icon: <AddParkingBuildingIcon width={20} height={20} />,
  }, {
    label: 'Owner Listings',
    path: '/parkit/owner-listings',
    icon: <OwnerListingsIcon width={20} height={20} />,
  }, {
    label: 'Parking Inventory',
    path: '/parkit/listings',
    icon: <ParkingInventoryIcon width={20} height={20} />,
  }, {
    label: 'Masterlist',
    path: '/parkit/masterlist',
    icon: <MasterlistIcon width={20} height={20} />,
  }, {
    label: 'Termination Requests',
    path: '/parkit/terminations',
    icon: <TerminationIcon width={20} height={20} />,
  }, {
    label: 'Appt. Status',
    path: '/parkit/appointment',
    icon: <AppointmentStatusOnfleetIcon width={20} height={20} />,
  }, {
    label: 'Customer',
    path: '/parkit/customers',
    icon: <ParkitUserIcon className="text-[#D9E2FF]" width={18} height={18} />,
  }, {
    label: 'Insurance',
    path: '/parkit/insurance',
    icon: <InsuranceIcon width={20} height={20} />,
  }, {
    label: 'Delete User Requests',
    path: '/parkit/delete-requests',
    icon: <DeleteUserIcon width={20} height={20} />,
  }, {
    label: 'Archived Listings',
    path: '/parkit/archived-listings',
    icon: <ArchivedListingsIcon width={20} height={20} />,
  }, {
    label: 'Archived Rentings',
    path: '/parkit/archived-rentings',
    icon: <ArchivedRentingsIcon width={20} height={20} />,
  }, {
    label: 'Delivery Rate',
    path: '/parkit/delivery-rate',
    icon: <DeliveryRateIcon width={20} height={20} />,
  }, {
    label: 'Affiliate Campaign',
    path: '/parkit/affiliate-campaigns',
    icon: <AffiliateCampaignIcon width={20} height={20} />,
  }, {
    label: 'Payout Reports',
    path: '/parkit/payout-reports',
    icon: <PayoutReportIcon width={20} height={20} />,
  },{
    label: 'Payout',
    path: '/parkit/payout',
    icon: <PayoutIcon width={20} height={20} />,
  }];

  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    backgroundColor: '#152C7D',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    top: toolbarHeight,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  });

  const closedMixin = (theme) => ({
    backgroundColor: '#152C7D',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    top: toolbarHeight,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  return (
    <div>
      <Drawer variant="permanent" open={open}>
        <div className="flex items-center p-3 bg-[#1C389A]">
          <IconButton sx={{ color: "white" }} onClick={() => open ? dispatch(closeParkitNav()) : dispatch(openParkitNav())}>
            {open ? (<div className="flex items-center h-[24px]"><CollapseMenuIcon width={18} height={18} /></div>) : (<MenuIcon />)}
          </IconButton>
        </div>
        <Divider />
        <List style={{ paddingBottom: 80 }}>
          {pages.map((page) => (
            <ListItem key={page.label} disablePadding className="block">
              <ListItemButton
                onClick={() => window.location.href = page.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                  title={page.label}
                >
                  {page.icon}
                </ListItemIcon>
                <ListItemText primary={page.label} className={`text-white ${open ? 'opacity-100' : 'opacity-0'}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default ParkitNav;
